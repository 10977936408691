html {
  font: normal 100% / 7msu var(--font-family-sans);
}

/* Headings */
h1 {
  margin: calc(7msu * 1rem) 0;
  text-align: center;
  font-weight: bold;
  font-size: calc(7msu * 1rem);
  line-height: 4msu;
}

h2 {
  margin: calc(9msu * 1rem) 0 calc(4msu * 1rem);
  font-weight: bold;
  font-size: calc(4msu * 1rem);
  line-height: 3msu;
}

h3 {
  margin: calc(6msu * 1rem) 0 calc(1msu * 0.15rem);
  font-weight: bold;
  font-size: calc(0msu * 1rem);
  line-height: 3msu;
}

p {
  margin: 0 0 calc(7msu * 1rem);
  font-family: var(--font-family-serif);
}

/* Main Navigation */
nav {
  & li {
    & a {
      font-size: calc(4msu * 1rem);
    }
  }
}

@media only screen and (min-width: 42em) {
  /*   html {
    font-size: 110%;
  } */
  h1 {
    text-align: left;
  }
}
